import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NgwWowModule } from 'ngx-wow';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { KnowmoreComponent } from './knowmore/knowmore.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { VideaComponent } from './videa/videa.component';
import { ContactComponent } from './contact/contact.component';
import { JoinusComponent } from './joinus/joinus.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { PaintCalculatorComponent } from './paint-calculator/paint-calculator.component';
import { ColorsComponent } from './colors/colors.component';
import { FaqComponent } from './faq/faq.component';
import { BranchLocatorComponent } from './branch-locator/branch-locator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DownloadsComponent } from './downloads/downloads.component';
import { PaintguideComponent } from './paintguide/paintguide.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { NewsComponent } from './news/news.component';
import { NewsdetailsComponent } from './newsdetails/newsdetails.component';
import { AllproductsComponent } from './allproducts/allproducts.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ProductsComponent,
    KnowmoreComponent,
    ProductdetailsComponent,
    VideaComponent,
    ContactComponent,
    JoinusComponent,
    BlogsComponent,
    BlogSingleComponent,
    PaintCalculatorComponent,
    ColorsComponent,
    FaqComponent,
    BranchLocatorComponent,
    DownloadsComponent,
    PaintguideComponent,
    CertificationsComponent,
    NewsComponent,
    NewsdetailsComponent,
    AllproductsComponent,
    SubcategoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgwWowModule,
    BrowserAnimationsModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
