import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }

  baseurl="https://nexonpaints.com/admin_cms/api/";
  baseimgurl="https://nexonpaints.com/admin_cms/";
 // baseurlold="https://nexon.antsdigital.in/api/";

  async getHomebanner(): Promise<any> {
    const response = await this.http.get(this.baseurl+'banners').toPromise();
    return response
  }

  async Getproducts(): Promise<any> {
    const response = await this.http.get(this.baseurl+'products').toPromise();
    return response
  }
  async getcategories(): Promise<any> {
    const response = await this.http.get(this.baseurl+'categories').toPromise();
    return response
  }
  async getsubcategories(slug:any): Promise<any> {
    return await this.http.get(this.baseurl+'subcategories/'+slug).pipe(shareReplay(1)).toPromise()
  }

  async getFaqs(): Promise<any> {
    const response = await this.http.get(this.baseurl+'faqs').toPromise();
    return response
  }

  async getNews(): Promise<any> {
    const response = await this.http.get(this.baseurl+'news').toPromise();
    return response
  }
  async getDownloads(): Promise<any> {
    const response = await this.http.get(this.baseurl+'downloads/get').toPromise();
    return response
  }

  async getBlogs(): Promise<any> {
    const response = await this.http.get(this.baseurl+'blogs').toPromise();
    return response
  }

  async getBlogDetails(slug:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'blogs/'+slug).toPromise();
    return response
  }

  async categories(): Promise<any> {
    const response = await this.http.get(this.baseurl+'categories').toPromise();
    return response
  }
  async getProductsByCategoryId(id:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'category/'+id).toPromise();
    return response
  }
  async getProductBySlug(slug:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'product/'+slug).toPromise();
    return response
  }

  async getProductUrl(slug:any){
    return this.http.get(this.baseurl +'products/'+ slug);
  }
  async getCommentList(blogId:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'comment/get/'+blogId).toPromise();
    return response
  }
  async getStateList(): Promise<any> {
    const response = await this.http.get(this.baseurl+'states/get').toPromise();
    return response
  }
  async getCityFromState(stateId:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'citybystateid/get/'+stateId).toPromise();
    return response
  }
  async getBranchesFromStateCity(stateId:any, cityId:any): Promise<any> {
    const response = await this.http.get(this.baseurl+'branches/get/'+stateId+'/'+cityId).toPromise();
    return response
  }

  // GetSingleBlogs(slug:any){
  //   return this.http.get(this.baseurl +'blog/'+ slug);
  // }


 
  CommentApi(commentdata: any){
    return this.http.post<any>(this.baseurl + 'comment/post',commentdata,
      { headers: new HttpHeaders({"Content-Type": "application/json; charset = utf-8;"}) })
  }
  shadeEnquiry(shadeData: any){
    return this.http.post<any>(this.baseurl + 'shadeenquiry/post',shadeData,
      { headers: new HttpHeaders({"Content-Type": "application/json; charset = utf-8;"}) })
  }
  newsletterSubscribe(data: any){
    return this.http.post<any>(this.baseurl + 'newsletter/post',data,
    { headers: new HttpHeaders({"Content-Type": "application/json; charset = utf-8;"}) })
  }

  MetaAbout(){
    return this.http.get<any>(this.baseurl + '/pagedetails/about-us')
  }

  MetaAllPages(slug:any){
    return this.http.get(this.baseurl +'pagedetails/'+ slug);
  }

  contactUs(contactdata: any){
    return this.http.post<any>(this.baseurl + 'contactus/post',contactdata,
       { headers: new HttpHeaders({"Content-Type": "application/json; charset = utf-8;"}) })
  }

  gerCareerData(){
    return this.http.get(this.baseurl +'careers');
  }

  postCareer(careerData){
    return this.http.post<any>(this.baseurl + 'career/post',careerData)
  }

}
